export const Endpoints = {
  baseUrl: "https://executiveheadlines.com/admin/api/",
  ImageUrl: "https://executiveheadlines.com/admin/media/",
  GetMagazine:"/magazine",
  GetTechnology:"/technology",
  GetIndustries:"/industries",
  GetBusinessNews:"/business_news",
  GetStartupInsight:"/startupInsight",
  GetStartupInsightHome:"/startupInsightHome",
  GetClientspeak:"/clientspeak",
  GetCoverStory:"/cover-story",
  GetCompanyLogo : "/client-logo",
  GetNews : "/news",
  GetCxo : "/cxo",
  GetNewsDetail : "/news-detail",
  GetVideo : "/video",
  GetSearch : "/search",
  GetNewNews : "/new-news",
  NewsLatter : "/news-letter",
  GetAbout : "/about",
  GetListing: "/listing",
  GetBanner: "/banner",
  ContactUs: "/ContactUs",
  GetHome: "/home",
};
